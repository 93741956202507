import { romNums } from '../../../utils/rom-nums';
import { Injectable } from '@angular/core';

export const Divisions = {
  'lol': [
    'Unranked',     // 00
    'Iron IV',      // 01
    'Iron III',     // 02
    'Iron II',      // 03
    'Iron I',       // 04
    'Bronze IV',    // 05
    'Bronze III',   // 06
    'Bronze II',    // 07
    'Bronze I',     // 08
    'Silver IV',    // 09
    'Silver III',   // 10
    'Silver II',    // 11
    'Silver I',     // 12
    'Gold IV',      // 13
    'Gold III',     // 14
    'Gold II',      // 15
    'Gold I',       // 16
    'Platinum IV',  // 17
    'Platinum III', // 18
    'Platinum II',  // 19
    'Platinum I',   // 20
    'Emerald IV',   // 21
    'Emerald III',  // 22
    'Emerald II',   // 23
    'Emerald I',    // 24
    'Diamond IV',   // 25
    'Diamond III',  // 26
    'Diamond II',   // 27
    'Diamond I',    // 28
    'Master',       // 29
    'Grandmaster',  // 30
    'Challenger',   // 31
  ],
  'tft': [
    'Unranked',     // 00
    'Iron IV',      // 01
    'Iron III',     // 02
    'Iron II',      // 03
    'Iron I',       // 04
    'Bronze IV',    // 05
    'Bronze III',   // 06
    'Bronze II',    // 07
    'Bronze I',     // 08
    'Silver IV',    // 09
    'Silver III',   // 10
    'Silver II',    // 11
    'Silver I',     // 12
    'Gold IV',      // 13
    'Gold III',     // 14
    'Gold II',      // 15
    'Gold I',       // 16
    'Platinum IV',  // 17
    'Platinum III', // 18
    'Platinum II',  // 19
    'Platinum I',   // 20
    'Emerald IV',   // 21
    'Emerald III',  // 22
    'Emerald II',   // 23
    'Emerald I',    // 24
    'Diamond IV',   // 25
    'Diamond III',  // 26
    'Diamond II',   // 27
    'Diamond I',    // 28
    'Master',       // 29
    'Grandmaster',  // 30
    'Challenger',   // 31
  ],
  'valorant': [
    'Unranked',       // 00
    'Iron I',         // 01
    'Iron II',        // 02
    'Iron III',       // 03
    'Bronze I',       // 04
    'Bronze II',      // 05
    'Bronze III',     // 06
    'Silver I',       // 07
    'Silver II',      // 08
    'Silver III',     // 09
    'Gold I',         // 10
    'Gold II',        // 11
    'Gold III',       // 12
    'Platinum I',     // 13
    'Platinum II',    // 14
    'Platinum III',   // 15
    'Diamond I',      // 16
    'Diamond II',     // 17
    'Diamond III',    // 18
    'Ascendant I',     // 19
    'Ascendant II',    // 20
    'Ascendant III',   // 21
    'Immortal I',     // 22
    'Immortal II',    // 23
    'Immortal III',   // 24
    'Radiant',        // 25
  ],
  'wild-rift': [
    'Unranked',     // 00
    'Iron IV',      // 01
    'Iron III',     // 02
    'Iron II',      // 03
    'Iron I',       // 04
    'Bronze IV',    // 05
    'Bronze III',   // 06
    'Bronze II',    // 07
    'Bronze I',     // 08
    'Silver IV',    // 09
    'Silver III',   // 10
    'Silver II',    // 11
    'Silver I',     // 12
    'Gold IV',      // 13
    'Gold III',     // 14
    'Gold II',      // 15
    'Gold I',       // 16
    'Platinum IV',  // 17
    'Platinum III', // 18
    'Platinum II',  // 19
    'Platinum I',   // 20
    'Emerald IV',   // 21
    'Emerald III',  // 22
    'Emerald II',   // 23
    'Emerald I',    // 24
    'Diamond IV',   // 25
    'Diamond III',  // 26
    'Diamond II',   // 27
    'Diamond I',    // 28
    'Master',       // 29
    'Grandmaster',  // 30
    'Challenger',   // 31
  ],
  'apex': [
    'Unranked',       // 00
    'Rookie IV',      // 01
    'Rookie III',     // 02
    'Rookie II',      // 03
    'Rookie I',       // 04
    'Bronze IV',      // 05
    'Bronze III',     // 06
    'Bronze II',      // 07
    'Bronze I',       // 08
    'Silver IV',      // 09
    'Silver III',     // 10
    'Silver II',      // 11
    'Silver I',       // 12
    'Gold IV',        // 13
    'Gold III',       // 14
    'Gold II',        // 15
    'Gold I',         // 16
    'Platinum IV',    // 17
    'Platinum III',   // 18
    'Platinum II',    // 19
    'Platinum I',     // 20
    'Diamond IV',     // 21
    'Diamond III',    // 22
    'Diamond II',     // 23
    'Diamond I',      // 24
    'Master',         // 25
    'Predator',       // 26
  ],
  // 'codmw2': [
  //   'Unranked',   //0
  //   'Bronze 1',   //1
  //   'Bronze 2',   //2
  //   'Bronze 3',   //3
  //   'Silver 1',   //4
  //   'Silver 2',   //5
  //   'Silver 3',   //6
  //   'Gold 1',     //7
  //   'Gold 2',     //8
  //   'Gold 3',     //9
  //   'Platinum 1', //10
  //   'Platinum 2', //11
  //   'Platinum 3', //12
  //   'Diamond 1',  //13
  //   'Diamond 2',  //14
  //   'Diamond 3',  //15
  //   'Crimson 1',  //16
  //   'Crimson 2',  //17
  //   'Crimson 3',  //18
  //   'Iridescent', //19
  //   'Top 250'     //20
  // ],
  // 'codwz2': [
  //   'Unranked',   //0
  //   'Bronze 1',   //1
  //   'Bronze 2',   //2
  //   'Bronze 3',   //3
  //   'Silver 1',   //4
  //   'Silver 2',   //5
  //   'Silver 3',   //6
  //   'Gold 1',     //7
  //   'Gold 2',     //8
  //   'Gold 3',     //9
  //   'Platinum 1', //10
  //   'Platinum 2', //11
  //   'Platinum 3', //12
  //   'Diamond 1',  //13
  //   'Diamond 2',  //14
  //   'Diamond 3',  //15
  //   'Crimson 1',  //16
  //   'Crimson 2',  //17
  //   'Crimson 3',  //18
  //   'Iridescent', //19
  //   'Top 250'     //20
  // ],
  'overwatch': [
    'Unranked',        //0
    'Bronze 5',        //1
    'Bronze 4',        //2
    'Bronze 3',        //3
    'Bronze 2',        //4
    'Bronze 1',        //5
    'Silver 5',        //6
    'Silver 4',        //7
    'Silver 3',        //8
    'Silver 2',        //9
    'Silver 1',        //10
    'Gold 5',          //11
    'Gold 4',          //12
    'Gold 3',          //13
    'Gold 2',          //14
    'Gold 1',          //15
    'Platinum 5',      //16
    'Platinum 4',      //17
    'Platinum 3',      //18
    'Platinum 2',      //19
    'Platinum 1',      //20
    'Diamond 5',       //21
    'Diamond 4',       //22
    'Diamond 3',       //23
    'Diamond 2',       //24
    'Diamond 1',       //25
    'Master 5',        //26
    'Master 4',        //27
    'Master 3',        //28
    'Master 2',        //29
    'Master 1',        //30
    'Grandmaster 5',   //31
    'Grandmaster 4',   //32
    'Grandmaster 3',   //33
    'Grandmaster 2',   //34
    'Grandmaster 1',   //35
    'Top 500'          //36
  ],
  'cs2': [
    '0',      // 0
    '1000',   // 1
    '2000',   // 2
    '3000',   // 3
    '4000',   // 4
    '5000',   // 5
    '6000',   // 6
    '7000',   // 7
    '8000',   // 8
    '9000',   // 9
    '10000',  // 10
    '11000',  // 11
    '12000',  // 12
    '13000',  // 13
    '14000',  // 14
    '15000',  // 15
    '16000',  // 16
    '17000',  // 17
    '18000',  // 18
    '19000',  // 19
    '20000',  // 20
    '21000',  // 21
    '22000',  // 22
    '23000',  // 23
    '24000',  // 24
    '25000',  // 25
    '26000',  // 26
    '27000',  // 27
    '28000',  // 28
    '29000',  // 29
    '30000',  // 30
    '31000',  // 31
    '32000',  // 32
    '33000',  // 33
    '34000',  // 34
    '35000'   // 35
  ],
  'marvel': [
    'Unranked',         // 00
    'Bronze III',       // 01
    'Bronze II',        // 02
    'Bronze I',         // 03
    'Silver III',       // 04
    'Silver II',        // 05
    'Silver I',         // 06
    'Gold III',         // 07
    'Gold II',          // 08
    'Gold I',           // 09
    'Platinum III',     // 10
    'Platinum II',      // 11
    'Platinum I',       // 12
    'Diamond III',      // 13
    'Diamond II',       // 14
    'Diamond I',        // 15
    'Grandmaster III',  // 16
    'Grandmaster II',   // 17
    'Grandmaster I',    // 18
    'Celestial III',    // 19
    'Celestial II',     // 20
    'Celestial I',      // 21
    'Eternity',         // 22
    'One Above All',    // 23
  ]
};

export const Masteries = {
  lol: [
    'Mastery 0', //0
    'Mastery 1', //1
    'Mastery 2', //2
    'Mastery 3', //3
    'Mastery 4', //4
    'Mastery 5', //5
    'Mastery 6', //6
    'Mastery 7', //7
    'Mastery 8', //8
    'Mastery 9', //9
    'Mastery 10+', //10
  ],
  cs2: [
    'Unranked',                     // 0
    'Silver I',                     // 1
    'Silver II',                    // 2
    'Silver III',                   // 3
    'Silver IV',                    // 4
    'Silver Elite',                 // 5
    'Silver Elite Master',          // 6
    'Gold Nova I',                  // 7
    'Gold Nova II',                 // 8
    'Gold Nova III',                // 9
    'Gold Nova Master',             // 10
    'Master Guardian I',            // 11
    'Master Guardian II',           // 12
    'Master Guardian Elite',        // 13
    'Distinguished Master Guardian',// 14
    'Legendary Eagle',              // 15
    'Legendary Eagle Master',       // 16
    'Supreme Master First Class',   // 17
    'Global Elite'                  // 18
  ]
}

export const Badges = {
  'apex': [
    { title: 'Legend\'s Wake', description: 'Kill at least 20 enemies in a trio game.', popular: true }, //0
    { title: 'Legend\'s Wrath I', description: 'Deal 2,000 damage in a single game', popular: false }, //1
    { title: 'Legend\'s Wrath II', description: 'Deal 2,500 damage in a single game.', popular: false }, //2
    { title: 'Legend\'s Wrath III', description: 'Deal 3,000 damage in a single game.', popular: false }, //3
    { title: 'Legend\'s Wrath IV', description: 'Deal 4,000 damage in a single game.', popular: true }, //4
    { title: 'Triple Triple', description: 'Kill all 3 members of 3 squads in the same match.', popular: true }, //5
    { title: 'Double Duty', description: 'Win a game where you were the Kill Leader and the Champion.', popular: false }, //6
    { title: 'Headshot Hotshot', description: 'Win a game with 5 headshot kills.', popular: false }, //7
    { title: 'Apex Predator', description: 'Win a game while being the Kill Leader.', popular: true }, //8
    { title: 'Rapid Elimination', description: 'Down 4 enemies within 20 seconds.', popular: false }, //9
    { title: 'Squad Wipe', description: 'Kill all 3 members of an enemy squad.', popular: false }, //10
    { title: 'No One Left Behind', description: 'Respawn both teammates in the same game.', popular: false }, //11
    { title: 'Deadeye', description: 'Get the last kill of a game.', popular: false }, //12
    { title: 'Shot Caller', description: 'Win a game as the Jumpmaster.', popular: false }, //13
    { title: 'Reinforcement Recall', description: 'Kill someone within 10 seconds of them landing from a respawn dropship.', popular: false }, //14
    { title: 'The Legacy Continues', description: 'Win a game where your full squad was alive at the end', popular: false }, //15
    { title: 'Hot Streak', description: 'Win 2 games in a row as the same legend.', popular: false }, //16
    { title: 'Flawless Victory I', description: 'Win a game where no one in the squad dies during the match.', popular: false }, //17
    { title: 'Flawless Victory II', description: 'Win a game where no one in the squad gets knocked down during the match.', popular: false }, //18
    { title: 'Win Streak', description: 'Win 2 matches in a row in Battle Royale.', popular: false }, //19
    { title: 'Win Streak II', description: 'Win 3 matches in a row in Battle Royale.', popular: false }, //20
    { title: 'Win Streak III', description: 'Win 4 matches in a row in Battle Royale.', popular: false }, //21
    { title: 'Win Streak IV', description: 'Win 5 matches in a row in Battle Royale.', popular: true }, //22
    { title: 'Fully Kitted', description: 'Equip two fully kitted weapons at the same time in a game.', popular: false }, //23
    { title: 'Long Shot', description: 'Down a player from a distance greater than 300 meters.', popular: false }, //24
    { title: 'No Witnesses', description: 'With a full pre-made squad, kill 15 players and no one who your squad downs is ever revived or resurrected.', popular: false }, //25
    { title: 'Well-Rounded', description: 'Deal 20,000 damage with 8 different legends.', popular: false }, //26
    { title: 'Master Of All', description: 'Win at least 10 times with 8 different legends.', popular: false }, //27
    { title: 'Team. Work. I', description: 'With a full pre-made squad, play a game where each player gets 3 kills.', popular: false }, //28
    { title: 'Team. Work. II', description: 'With a full pre-made squad, play a game where each player gets 5 kills.', popular: false }, //29
    { title: 'Team. Work. III', description: 'With a full pre-made squad, play a game where each player gets 7 kills.', popular: false }, //30
    { title: 'Team. Work. IV', description: 'With a full pre-made squad, play a game where each player gets 10 kills.', popular: false }, //31
    { title: 'Group Theatrics I', description: 'With a full pre-made squad, win 1 game where each person executes an enemy.', popular: false }, //32
    { title: 'Group Theatrics II', description: 'With a full pre-made squad, win 2 games where each person executes an enemy.', popular: false }, //33
    { title: 'Group Theatrics III', description: 'With a full pre-made squad, win 3 games where each person executes an enemy.', popular: false }, //34
    { title: 'Legend\'s Wake (Solo)', description: 'Kill at least 20 enemies in a solo game.', popular: false }, //35 - "REMOVED"
  ],
  'apexRemovedBadgesIds': [35]
}

const PointsName = {
  'lol': 'LP',
  'tft': 'LP',
  'wild-rift': 'Marks',
  'valorant': 'RR',
  'apex': 'RP',
  'marvel': 'P',
}

@Injectable()
export class DivisionsService {
  public static getPointsName(game) {
    return PointsName[game];
  }

  public static getMasteryName(mastery, game) {
    return Masteries[game][mastery];
  }

  public static getMasteryValue(mastery, game) {
    return Masteries[game].findIndex(el => el.toLowerCase() === mastery.toLowerCase());
  }

  public static getBadgeTitle(value, game) {
    return Badges[game][value].title;
  }

  public static getRankByValue(value, game) {
    return Divisions[game][value];
  }

  public static getDivisionNameByValue(value, game) {
    if (((game === 'lol' || game === 'tft') && Divisions[game][value].startsWith('Diamond'))) {
      return Divisions[game][value];
    }

    return Divisions[game][value].split(' ')[0];
  }

  public static getRankNameByValue(value, game) {
    let name = 'DIVISION';
    if (game === 'marvel') {
      name = 'TIER';
    }
    return `${name} ${(Divisions[game][value].split(' ')[1] || '')}`;
  }

  public static getValueByRank(rank, game) {
    return Divisions[game].findIndex(el => el.toLowerCase() === rank.toLowerCase());
  }

  public static getRankShortByValueNew(value, game) {
    const fullRank = DivisionsService.getRankByValue(value, game);

    if (fullRank === 'Grandmaster') {
      return 'gm';
    } else if (fullRank === 'Challenger') {
      return 'ch';
    }

    let miniRank = fullRank.charAt(0);

    if (fullRank.indexOf('IV') > -1) {
      miniRank += '4';
    } else if (fullRank.indexOf('III') > -1) {
      miniRank += '3';
    } else if (fullRank.indexOf('II') > -1) {
      miniRank += '2';
    } else if (fullRank.indexOf('I') > -1) {
      miniRank += '1';
    }
    // else {
    //   miniRank += fullRank.charAt(1);
    // }

    return miniRank;
  }

  public static getRankShortByValue(value, game) {
    const rank = DivisionsService.getRankByValue(value, game);
    const fragments = rank.split(' ');
    if (fragments.length > 1) {
      let division;
      if (fragments[0] === 'Immortal') {
        division = 'im';
      } else {
        division = rank[0].toLowerCase();
      }
      const sub = romNums.indexOf(fragments[1]);

      let div = division + (sub > -1 ? sub : '');
      return div.toUpperCase();
    }
    return fragments[0].substring(0, 2).toUpperCase();
  }
}
